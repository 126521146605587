
export const addressList = {
    feeManager: {
        97: '0x1Ed0481C82Cd04a4bF6D23B50F015CaB09E09E48',
        56: '0x1Ed0481C82Cd04a4bF6D23B50F015CaB09E09E48',
    },
    NFTSpecV1: {
        97: '0xD2B9c56c8b67e2d8ffCb8bAEE7284D4871860184',
        56: '0xD2B9c56c8b67e2d8ffCb8bAEE7284D4871860184',
    },
    AloraNode: {
        97: '0xE83CC29cA8B95068c8EA38BB9C2FA56BBF27Ae42',
        56: '0xE83CC29cA8B95068c8EA38BB9C2FA56BBF27Ae42',
    },
    NodeManager: {
        97: '0x6a41EaC479f6C6A7558F03b52719C60a7E0F51c8',
        56: '0x6a41EaC479f6C6A7558F03b52719C60a7E0F51c8',
    },
    BoostNFT: {
        97: '0xc9fF330300CD23d9cc238A951dD465F2BaFec068',
        56: '0xc9fF330300CD23d9cc238A951dD465F2BaFec068',
    },
    Multicall: {
        97: '0x7a48b8094ccd7053af2D4E188e10E2b8c0dDDc2e',
        56: '0x7a48b8094ccd7053af2D4E188e10E2b8c0dDDc2e',
    },
    Busd: {
        97: '0x88E435986386575f5A7383bFe894FAdeC34FB73F',
        56: '0x88E435986386575f5A7383bFe894FAdeC34FB73F'
    }
}