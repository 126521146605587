 export const whitelisters = [
    '0x7ACf46627094FA89339DB5b2EB862F0E8Ea4D9fc', 
    '0x37f4a13f3Af9a8397d09c0Dc3ca508dB6181e492', 
    '0xf0EBDd31b3C62683f6463E67aCD14D9cE4FA4d43',
    '0x25eb1e647261C7DbE696536572De61b1a302a83C',
    '0xf35B008dC2737E4394BF0b671E54A74d2fFDD9a3',
    '0xFFE4e3C809418aEFB00922ffCa6b46d9d8f41B6c',
    '0x9659c67959F73303825E78Ddf445a42ef07Cb0DD',
    '0xD49496948533E5aEEE247f7B3380aF6a1040fE7E',
    '0x1793890908303f637745071abAB41453fcFbaF13'
];
